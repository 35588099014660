<template>
  <div>
    <PageTitlebar
      title="Business Settings"
      title-icon="sym_r_settings"
      sub-title="Keep your business information up to date."
    >
      <!--<template v-slot:bottom>
        <small class="block q-mt-sm text-font-secondary">
          <b>Created On:</b> {{ convertUtcToBusinessTimezone($store.state.business.record.time_created, "lll") }}
        </small>
      </template>-->
    </PageTitlebar>

    <q-form @submit="editBusiness">
      <FormCard
        title="Business Details"
        message="Some basic information about your account."
      >
        <!--<template v-slot:message>
          Some basic information about your account.
          <PageBanner
            title="Please Note"
            message="This address will be used for billing."
            type="info"
            class="q-mt-md"
            flat
            bordered
          />
        </template>-->

        <template v-slot:inputs>
          <q-input
            filled
            v-model="formData.name"
            label="Business Name"
            lazy-rules
            :rules="[
              val =>
                (val && val.length > 1) || 'Enter a name for your business.'
            ]"
            :disable="submitting"
          />
          <q-select
            filled
            v-model="formData.timezone"
            :options="[
              'America/Chicago',
              'America/Denver',
              'America/Los_Angeles',
              'America/New_York'
            ]"
            label="Timezone"
            :rules="[val => (val && val.length > 0) || 'Select a Timezone.']"
            :disable="submitting"
          />
        </template>
      </FormCard>

      <FormActionsSection>
        <template v-slot:primary-actions>
          <q-btn
            label="Save Changes"
            type="submit"
            color="primary"
            :disable="submitting"
            :loading="submitting"
          />
        </template>
        <!--<template v-slot:secondary-actions>
          <small class="text-font-secondary">
            <b>Last Updated:</b> {{ convertUtcToBusinessTimezone($store.state.business.record.time_updated, "lll") }}
          </small>
        </template>-->
      </FormActionsSection>
    </q-form>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import FormCard from "@/components/UI/FormCard.vue";
import FormActionsSection from "@/components/UI/FormActionsSection.vue";
// import PageBanner from "@/components/UI/PageBanner.vue";

export default {
  name: "AdvertiserBusinessSettings",
  components: {
    FormActionsSection,
    // PageBanner,
    FormCard,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      submitting: false,
      formData: {
        name: this.$store.state.business.record.name,
        timezone: this.$store.state.business.record.timezone
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    editBusiness() {
      this.submitting = true;

      this.$store
        .dispatch("business/POST_REQUEST", {
          endpoint: "update",
          params: {
            id: this.$store.state.business.record.id,
            name: this.formData.name,
            timezone: this.formData.timezone
          }
        })
        .then(() => {
          this.$store.dispatch("business/POPULATE_RECORD");
          this.triggerActionOutcomeAlert("save", true);
        })
        .catch(e => {
          console.log(e);
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
